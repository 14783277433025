import { NextApiRequest, NextApiResponse } from "next";

export type manageDiabetesData = {
  heading: string;
  subHeading: string;
  data: manageDiabetesSteps;
};
export type manageDiabetesSteps = typeof manageDiabetesSteps;
export type manageDiabetesStep = (typeof manageDiabetesSteps)[0];

export type cgmJoinOurProgramDataType = {
  heading: string;
  subHeading: string;
  data: cgmJoinOurProgramSteps;
};
export type cgmJoinOurProgramSteps = typeof cgmJoinOurProgramSteps;
export type cgmJoinOurProgramStep = (typeof cgmJoinOurProgramSteps)[0];

const manageDiabetesSteps = [
  {
    title: "Nutrition",
    icon: "https://assets.bonatra.com/package_detail/water-drop-1.webp",
    paragraph1:
      "A balanced diet fuels the body's functions with essential nutrients, promoting cell repair, disease prevention, and overall well-being.",
    paragraph2:
      "Unlock the secrets of food's influence on your glucose levels through Bonatra's advanced CGM aided solutions.",
    image:
      "https://assets.bonatra.com/package_detail/Frame%201181_iphone13midnight_portrait.webp",
    alt: "Continous Glucose Monitor icon - bonatra",
  },
  {
    title: "Physical Activity",
    icon: "https://assets.bonatra.com/package_detail/stethoscope%20(1)%201.webp",
    paragraph1:
      "Exercise boosts heart health, strengthens muscles and bones, and improves metabolism. It enhances overall well-being, lowers disease risks, and adds years to life by preserving physical resilience.",
    paragraph2:
      "Bonatra's CGM solution features a metabolic score tracking calorie use for vital functions, guiding weight management with energy expenditure insights.",
    image: "https://assets.bonatra.com/package_detail/One%20on%20one%20DC%20AND.webp",
    alt: "Best-in-class Doctor Consultations icon - bonatra",
  },
  {
    title: "Quality Sleep",
    icon: "https://assets.bonatra.com/package_detail/task-actions-1.webp",
    paragraph1:
      "Getting enough sleep helps your body recover and stay strong. It's like a boost for your brain and immune system. When you rest well, your body can work efficiently, keeping you healthy and energized for the long run.",
    paragraph2:
      "Bonatra's Smart Ring X1 assesses sleep quality, analyzes sleep cycles and disturbances, and generates a readiness score to let you know how prepared you are for the next day.",
    image:
      "https://assets.bonatra.com/package_detail/deir%20plan%20and%20fitness%20plan%20AND%20(1).webp",
    alt: "Personalised Fitness Plan & Diet Plan icon - bonatra",
  },
  {
    title: "Stress Management",
    icon: "https://assets.bonatra.com/package_detail/fingerprint%201.webp",
    paragraph1:
      "Chronic stress speeds ageing, but techniques like meditation and relaxation keep hormones balanced, reduce inflammation, and protect against stress's harm.",
    paragraph2:
      "Bonatra’s Ring tracks stress via HRV and RHR indicators. Resting Heart Rate (RHR) shows heartbeats at rest, indicating cardiovascular health, fitness, stress, and overall well-being. HRV measures heartbeat variations, reflecting the autonomic nervous system's balance.",
    image: "https://assets.bonatra.com/package_detail/Dashboard%20AND.webp",
    alt: "App Access icon - bonatra",
  },
  {
    title: "Social Engagement",
    icon: "https://assets.bonatra.com/package_detail/task-actions-1.webp",
    paragraph1:
      "Meaningful social connections provide emotional support and a sense of belonging, which have been linked to reduced stress and lower mortality rates.",
    paragraph2:
      "Gain access to Bonatra's community that is incredibly supportive; experienced doctors, nutritionists and health coaches.",
    image:
      "https://assets.bonatra.com/package_detail/deir%20plan%20and%20fitness%20plan%20AND%20(1).webp",
    alt: "Personalised Fitness Plan & Diet Plan icon - bonatra",
  },
  {
    title: "Avoiding Harmful Habits",
    icon: "https://assets.bonatra.com/package_detail/task-actions-1.webp",
    paragraph1:
      "Avoiding harmful habits like smoking and excessive alcohol prevents organ damage and chronic diseases. It's crucial for vitality and longer life.",
    paragraph2:
      "The SPO2 level indicator in Bonatra ring is your perfect accountability partner that helps you get rid of your harmful habits like smoking.",
    image:
      "https://assets.bonatra.com/package_detail/deir%20plan%20and%20fitness%20plan%20AND%20(1).webp",
    alt: "Personalised Fitness Plan & Diet Plan icon - bonatra",
  },
  {
    title: "Cognitive Health",
    icon: "https://assets.bonatra.com/package_detail/task-actions-1.webp",
    paragraph1:
      "Nurturing an active brain with challenges and learning enhances cognitive resilience, guarding against decline and diseases. A sharp mind is your passport to a vibrant life as you age.",
    paragraph2:
      "Bonatra Ring X1 calculates your mindfulness score by analysing your breathing and heartbeat, reflecting how well you embody mindfulness in your daily life.",
    image:
      "https://assets.bonatra.com/package_detail/Frame%201185_iphone13midnight_portrait.webp",
    alt: "Personalised Fitness Plan & Diet Plan icon - bonatra",
  },
  {
    title: "Purpose and Gratitude",
    icon: "https://assets.bonatra.com/package_detail/task-actions-1.webp",
    paragraph1:
      "Fostering purpose and gratitude brings meaning and positivity to life. They reduce stress, improve mental health, and enhance well-being, supporting a longer, healthier life.",
    image:
      "https://assets.bonatra.com/package_detail/deir%20plan%20and%20fitness%20plan%20AND%20(1).webp",
    alt: "Personalised Fitness Plan & Diet Plan icon - bonatra",
  },
];

const cgmJoinOurProgramSteps = [
  {
    title: "Continous Glucose Monitor",
    icon: "https://assets.bonatra.com/package_detail/water-drop-1.webp",
    paragraph1:
      "Bonatra's app seamlessly incorporates CGM, enabling users to continuously monitor their blood sugar levels while gaining valuable insights into the effects of different foods on their body. Empowered by this knowledge, individuals can make informed dietary decisions and achieve lower fat accumulation and higher fat burn leading to better health outcomes.",
    paragraph2: "",
    image: "https://assets.bonatra.com/package_detail/CGM%20AND.webp",
    alt: "Continous Glucose Monitor icon - bonatra",
  },
  {
    title: "Best-in-class Doctor Consultations",
    icon: "https://assets.bonatra.com/package_detail/stethoscope%20(1)%201.webp",
    paragraph1:
      "Bonatra's doctor programs provide continuous support from expert physicians throughout the journey, ensuring personalized care and guidance every step of the way. With regular monitoring of progress, dietary and fitness plans are dynamically adjusted based on your body's response, maximizing effectiveness and ensuring optimal results. With this seamless adaptability, individuals experience a transformative health journey, tailor-made to their unique needs and goals.",
    paragraph2: "",
    image: "https://assets.bonatra.com/package_detail/One%20on%20one%20DC%20AND.webp",
    alt: "Best-in-class Doctor Consultations icon - bonatra",
  },
  {
    title: "Personalised Fitness Plan & Diet Plan",
    icon: "https://assets.bonatra.com/package_detail/task-actions-1.webp",
    paragraph1:
      "Embrace a fitness and diet plan that evolves with you, integrating CGM data, medical history, and food insights to provide personalized care and optimize your health journey. With Bonatra's expert support and real-time adjustments, achieving your fitness goals becomes a seamless and transformative experience.",
    paragraph2: "",
    image:
      "https://assets.bonatra.com/package_detail/deir%20plan%20and%20fitness%20plan%20AND%20(1).webp",
    alt: "Personalised Fitness Plan & Diet Plan icon - bonatra",
  },
  {
    title: "App Access",
    icon: "https://assets.bonatra.com/package_detail/fingerprint%201.webp",
    paragraph1:
      "With Bonatra's app access, you can achieve comprehensive health tracking by seamlessly integrating with Google Fit and Apple Fit. Effortlessly monitor blood glucose levels using your smartphone's scanning feature, while detailed food logs provide invaluable health insights, all within the convenience of the Bonatra app.",
    paragraph2: "",
    image: "https://assets.bonatra.com/package_detail/Dashboard%20AND.webp",
    alt: "App Access icon - bonatra",
  },
];

const cgmProgramsSteps = [
  {
    title: "Continous Glucose Monitor",
    icon: "https://assets.bonatra.com/package_detail/water-drop-1.webp",
    paragraph1:
      "With CGM integration on Bonatra's app, users can effortlessly track their blood sugar levels in real-time and discover how food choices impact their body. This invaluable information aids in making smarter dietary decisions, helping users take control of their health and well-being like never before.",
    paragraph2: "",
    image: "https://assets.bonatra.com/package_detail/CGM%20AND.webp",
    alt: "Continous Glucose Monitor icon - bonatra",
  },
  {
    title: "Best-in-class Doctor Consultations",
    icon: "https://assets.bonatra.com/package_detail/stethoscope%20(1)%201.webp",
    paragraph1:
      "Embark on a transformative health journey with Bonatra's doctor programs, where continuous support from expert physicians accompanies you. As your body responds and progresses, dietary and fitness plans are dynamically adjusted to ensure your success in achieving your health goals.",
    paragraph2: "",
    image: "https://assets.bonatra.com/package_detail/One%20on%20one%20DC%20AND.webp",
    alt: "Best-in-class Doctor Consultations icon - bonatra",
  },
  {
    title: "Personalised Fitness Plan & Diet Plan",
    icon: "https://assets.bonatra.com/package_detail/task-actions-1.webp",
    paragraph1:
      "Bonatra's fitness and diet plans are designed with adaptability at their core, dynamically adjusting as users progress through their health journey. By integrating CGM data, medical history, and food insights, Bonatra's doctor-led plans offer personalized care and optimization, ensuring individuals achieve their fitness goals efficiently and effectively. Embrace a transformative experience, guided by real-time data and expert support, on your path to improved well-being with Bonatra's fitness and diet plans.",
    paragraph2: "",
    image:
      "https://assets.bonatra.com/package_detail/deir%20plan%20and%20fitness%20plan%20AND%20(1).webp",
    alt: "Personalised Fitness Plan & Diet Plan icon - bonatra",
  },
  {
    title: "App Access",
    icon: "https://assets.bonatra.com/package_detail/fingerprint%201.webp",
    paragraph1:
      "Discover comprehensive health tracking with Bonatra's app access, integrating Google Fit & Apple Fit seamlessly. Effortlessly monitor blood glucose levels with your smartphone's scanning feature and log detailed food information for enhanced health insights, all at your fingertips within the Bonatra app.",
    paragraph2: "",
    image: "https://assets.bonatra.com/package_detail/Dashboard%20AND.webp",
    alt: "App Access icon - bonatra",
  },
];

export const manageDiabetesData = {
  data: manageDiabetesSteps,
  heading: "Join our Program",
  subHeading: "Find your way back to good health",
};

export const cgmJoinOurProgramData = {
  data: cgmJoinOurProgramSteps,
  heading: "Join our Program",
  subHeading: "Find your way back to good health",
};

export const cgmProgramsData = {
  data: cgmProgramsSteps,
  heading: "Join our Program",
  subHeading: "Find your way back to good health",
};

export default function handler(
  req: NextApiRequest,
  res: NextApiResponse<manageDiabetesData>
) {
  res
    .status(200)
    .json({
      data: manageDiabetesSteps,
      heading: "Manage your Diabetes with us",
      subHeading: "Reverse your diabetes with simple and easy method",
    });
}
