import useGetListOfTestimonials from "@/lib/hooks/QueryHooks/useGetListOfTestimonials";
import Paragraph from "@/lib/Layout/Wrappers/Paragraph";
import {
  useColorModeValue,
  Heading,
  Box,
  Flex,
  Text,
  Divider,
  Container,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  HiOutlineArrowNarrowRight,
  HiOutlineArrowNarrowDown,
} from "react-icons/hi";
import Image from "next/image";
import { useRouter } from "next/router";
import { logEvent } from "@/analytics";

function LandingPageTestimonials({ pathName,isWebinarPage,isFitnessDetail, isDiabetesDetail,isPackages,isBulkCGM  }: { pathName?: string,isWebinarPage?: boolean,isFitnessDetail?: boolean,isDiabetesDetail?: boolean,isPackages?: boolean,isBulkCGM?: boolean }) {
  const { data: testimonialData, isLoading } = useGetListOfTestimonials({ categories: pathName });
  const navigation = useRouter();

  function handleClick() {
    const component = document.querySelector("#leadsForm");
    component?.scrollIntoView({ behavior: "smooth" });
  }

  if (isLoading) {
    return (
      <div>
        <p>is loading</p>
      </div>
    );
  }

  if (!testimonialData) {
    return null;
  }

  const cachedData = { ...testimonialData };

  const sortedKeys = [pathName, ...Object.keys(cachedData).filter((key) => key !== pathName)];

  return (
    <div className="flex flex-col pt-10">
      <div className="w-full bg-black py-8">
        <h2 className="text-white text-center text-2xl font-medium">
          5000+ Success Stories
        </h2>
      </div>
      <div className="relative">
      <div className="mx-auto my-10 w-full whitespace-nowrap overflow-hidden">
  <div className="flex gap-4 snap-mandatory snap-x overflow-x-scroll">
    {sortedKeys.map((element: string | undefined, index: number) => {
      if (!element) return null; // Skip undefined values

      return (
        <React.Fragment key={element}>
          {testimonialData[element]?.map((item: any, id: React.Key | null | undefined) => (
            <div key={id ?? index} className="snap-center sm:flex-none px-4 py-2">
              <TestimonialCard currentTestimonial={item} pathName={pathName || ''} />
            </div>
          ))}
        </React.Fragment>
      );
    })}
  </div>
</div>

      </div>
      <button
        id="landing_page_testimonial"
        className="bg-black w-auto text-white px-8 py-4 rounded-lg mx-auto my-4 text-2xl"
        onClick={() => {
          handleClick();
          logEvent("LandingPageTestimonial", "Book a free consultation");
          (window as any).fbq(
            "trackCustom",
            "CGM-Landing-Page-Testimonial-Free-Consultation"
          );
        }}
      >
       {isWebinarPage ?  "Register" : (isFitnessDetail || isDiabetesDetail || isPackages || isBulkCGM )  ? "Buy Now" : "Book a free consultation"}
      </button>
    </div>
  );
}

export const TestimonialCard = ({
  currentTestimonial,
  pathName
}: {
  currentTestimonial: any;
  pathName: string
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const trimmedText = currentTestimonial?.content && currentTestimonial?.content?.slice(0, 200) + '...';
  const height = showFullText ? ["800px", "800px"] : "auto";

  return (
    <>
      <Box
        w={["350px", "370px"]}
        bg={useColorModeValue("white", "gray.900")}
        boxShadow={"2xl"}
        rounded={"lg"}
        p={2}
        h={height}
        textAlign={"center"}
      >
        <div className="relative w-24 md:w-24 h-24 rounded-full m-auto bg-gray-200 my-6">
          <Image src={currentTestimonial?.picture} alt="avatar" layout="fill" style={{ objectFit: "contain", borderRadius: "50%" }} />
        </div>
        <Heading fontSize={"2xl"} fontFamily={"body"} fontWeight={"regular"}>
          {currentTestimonial?.patient_name}
        </Heading>
        <Divider />
        <Container pt="2">
          {!(pathName === "fitness") ? (   <Flex justifyContent="center">
            <div className="flex flex-col gap-2 h-48 md:h-48 justify-center md:justify-center items-center md:items-start">
              {currentTestimonial?.current_hba1c_ && (
                <div className="flex items-center gap-4">
                  <Paragraph className="font-medium">HbA1c :</Paragraph>
                  <Paragraph>
                    {String(currentTestimonial?.previous_hba1c_) + "%"}
                  </Paragraph>
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="text-black mt-1"
                  />
                  <Paragraph className="text-primaryGreen font-medium">
                    {String(currentTestimonial?.current_hba1c_ + "%")}
                  </Paragraph>
                </div>
              )}

              {currentTestimonial?.current_insulin_iu && (
                <div className="flex items-center md:items-start gap-4">
                  <Paragraph className="font-medium">Sugar Level :</Paragraph>
                  <Paragraph>
                    {String(currentTestimonial?.current_hba1c_) + " mg/dL"}
                  </Paragraph>
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="text-black mt-1"
                  />
                  <Paragraph className="text-primaryGreen font-medium">
                    {String(currentTestimonial?.current_hba1c_ + " mg/dL")}
                  </Paragraph>
                </div>
              )}
              {currentTestimonial?.current_weight_in_kg && (
                <div className="flex items-center md:items-start md:justify-start gap-4">
                  <Paragraph className="font-medium">Weight :</Paragraph>
                  <Paragraph>
                    {String(currentTestimonial?.previous_weight_in_kg) + " kgs"}
                  </Paragraph>
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="text-black mt-1"
                  />
                  <HiOutlineArrowNarrowDown
                    size={20}
                    className="text-primaryGreen mt-1"
                  />
                  <Paragraph className="text-primaryGreen font-medium">
                    {String(currentTestimonial?.current_weight_in_kg + " kgs")}
                  </Paragraph>
                </div>
              )}

              {currentTestimonial?.current_fbs_mgdl && (
                <div className="flex items-center md:items-start md:justify-start gap-4">
                  <Paragraph className="font-medium">FBS :</Paragraph>
                  <Paragraph>
                    {String(currentTestimonial?.previous_fbs_mgdl) + " mg/dL"}
                  </Paragraph>
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="text-black mt-1"
                  />
                  <HiOutlineArrowNarrowDown
                    size={20}
                    className="text-primaryGreen mt-1"
                  />
                  <Paragraph className="text-primaryGreen font-medium">
                    {String(currentTestimonial?.current_fbs_mgdl + " mg/dL")}
                  </Paragraph>
                </div>
              )}

              {currentTestimonial?.current_pp_blood_sugar_mgdl && (
                <div className="flex items-center md:items-start md:justify-start gap-4">
                  <Paragraph className="font-medium">PPBS :</Paragraph>
                  <Paragraph>
                    {String(currentTestimonial?.previous_pp_blood_sugar_mgdl) + " mg/dL"}
                  </Paragraph>
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="text-black mt-1"
                  />
                  <HiOutlineArrowNarrowDown
                    size={20}
                    className="text-primaryGreen mt-1"
                  />
                  <Paragraph className="text-primaryGreen font-medium">
                    {String(currentTestimonial?.current_pp_blood_sugar_mgdl + " mg/dL")}
                  </Paragraph>
                </div>
              )}
            </div>
          </Flex>) : ( 
              <Flex justifyContent="center">
            <div className="flex flex-col gap-2 h-48 md:h-48 justify-center md:justify-center items-center md:items-start">
            {currentTestimonial?.current_weight_in_kg && (
                <div className="flex items-center md:items-start md:justify-start gap-4">
                  <Paragraph className="font-medium">Weight :</Paragraph>
                  <Paragraph>
                    {String(currentTestimonial?.previous_weight_in_kg) + " kgs"}
                  </Paragraph>
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="text-black mt-1"
                  />
                  <HiOutlineArrowNarrowDown
                    size={20}
                    className="text-primaryGreen mt-1"
                  />
                  <Paragraph className="text-primaryGreen font-medium">
                    {String(currentTestimonial?.current_weight_in_kg + " kgs")}
                  </Paragraph>
                </div>
              )}


{((currentTestimonial?.previous_bmi) && (currentTestimonial?.previous_bmi != 0 )) ? (
                <div className="flex items-center md:items-start md:justify-start gap-4">
                  <Paragraph className="font-medium">BMI :</Paragraph>
                  <Paragraph>
                    {String(currentTestimonial?.previous_bmi)}
                  </Paragraph>
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="text-black mt-1"
                  />
                  <HiOutlineArrowNarrowDown
                    size={20}
                    className="text-primaryGreen mt-1"
                  />
                  <Paragraph className="text-primaryGreen font-medium">
                    {String(currentTestimonial?.current_bmi)}
                  </Paragraph>
                </div>
              ) : (<></>)}


               {(currentTestimonial?.current_weight_in_kg && currentTestimonial?.previous_waist_size != 0) && (
                <div className="flex items-center md:items-start md:justify-start gap-4">
                  <Paragraph className="font-medium">Waist Size :</Paragraph>
                  <Paragraph>
                    {String(currentTestimonial?.previous_waist_size) + " kgs"}
                  </Paragraph>
                  <HiOutlineArrowNarrowRight
                    size={20}
                    className="text-black mt-1"
                  />
                  <HiOutlineArrowNarrowDown
                    size={20}
                    className="text-primaryGreen mt-1"
                  />
                  <Paragraph className="text-primaryGreen font-medium">
                    {String(currentTestimonial?.current_waist_size + " kgs")}
                  </Paragraph>
                </div>
              )}


                   
            </div>
          </Flex>)}
       
        </Container>
        <Divider />
        <div className="w-full whitespace-normal break-words pt-2 h-32">
          <Text
            textAlign={"center"}
            color={useColorModeValue("gray.700", "gray.400")}
            px={1}
            fontSize="md"
            fontWeight="regular"
          >
            {showFullText ? `"${currentTestimonial?.content}"` : `"${trimmedText}"`}
            {currentTestimonial?.content.length > 200 && (
              <button onClick={() => setShowFullText(!showFullText)} style={{ color: "black", fontWeight: "900" }}>
                {showFullText ? 'Read less' : 'Read more'}
              </button>
            )}
          </Text>
        </div>
      </Box>
    </>
  );
};

export default LandingPageTestimonials;