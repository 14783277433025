import type { NextApiRequest, NextApiResponse } from 'next'

export type featuresData = {
    data: features;
    heading:string;
}

export type BonatraFeatures = {
  id: any | number | bigint;
  title:
    | string
    | number
    | boolean
    | React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  description1?:
    | string
    | number
    | boolean
    | React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
    description2?:
    | string
    | number
    | boolean
    | React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
    description3?:
    | string
    | number
    | boolean
    | React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}

export type BonatraFeatures2 = {
  id: any | number | bigint;
  description:
    | string
    | number
    | boolean
    | React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
      >
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}

export type features = typeof bonatraFeatures;

export const bonatraFeatures = [
    {
      id: 1,
      title: "Medical Science:",
      description1:
        "Guided by medical expertise, Bonatra's approach is rooted in evidence-based practices.",
        description2: "Our programs are designed to align with established health guidelines and recommendations.",
        description3: "Medical research forms the foundation of our strategies, ensuring safety and efficacy."
      
    },
    {
      id: 2,
      title: "Data Science",
      description1: "Bonatra harnesses the power of data analytics to personalise your wellness journey.",
      description2: "Your unique health data is analysed to provide tailored insights and recommendations.",
      description3: "Tracking progress over time enables adjustments for continuous improvement.",
    },
    {
      id: 3,
      title: "Technology",
      description1: "Our cutting-edge technology integrates seamlessly into your daily routine.",
      description2: "Devices collect real-time data, allowing you to monitor and optimise your health on the go",
      description3: "User-friendly App interface ensures that technology enhances, not complicates, your experience.",
      
    },
    {
      id: 4,
      title: "Human Experts",
       description1: "Bonatra's team includes experienced healthcare professionals and well-being specialists.",
      description2: "Human experts interpret data, offering personalised guidance based on your individual needs.",
      description3: "Access to Doctors, Nutritionists, Health coaches provides assurance and personalised support.",
    },
   ];

   export const diabetesReversalFeatures = [
    {
      id: 1,
      description:
        "Make the smart choice by selecting Bonatra, backed by proven results and a multitude of success stories, showcasing our dedication to your well-being.",
    },
    {
      id: 2,
      description:
        "With a primary focus on adherence, Bonatra ensures your health goals are achieved through scheduled follow-ups by our team of doctors, keeping you on course. ",
    },
    {
      id: 3,
      description:
        "Experience the convenience of Bonatra's simple 3-meal plans, featuring smart swaps such as keto parathas in lieu of regular ones and wholesome foxtail millet upmas. Unlock a delightful culinary journey while making progress towards your health goals with Bonatra.",
    },
    {
      id: 4,
      description:
        "At Bonatra, our Health Coach becomes your dedicated partner, ensuring equal partnership and accountability in your pursuit of better health.",
    },
    {
      id: 5,
      description:
        "Rest assured with the Doctor's approval, as they expertly handhold you through the programs, personalized to your needs and goals.",
    },
    {
      id: 6,
      description:
        "Join our thriving community of health enthusiasts, embracing better well-being and support with Bonatra.",
    },
   ];

   export const fitnessFeatures = [
    {
      id: 1,
      description:
        "Elevate your health journey with Bonatra, backed by proven results and a wealth of success stories that exemplify our commitment to your well-being.",
    },
    {
      id: 2,
      description:
        "At Bonatra, we prioritize driving adherence to your health goals, with our team of doctors providing scheduled follow-ups to keep you on the right path.",
    },
    {
      id: 3,
      description:
        "Simplify your meal routine with Bonatra's easy-to-follow 3-meal plans, incorporating subtle yet healthful replacements like keto parathas instead of traditional ones and delectable foxtail millet upmas. Elevate your well-being effortlessly with our thoughtfully curated meal options.",
    },
    {
      id: 4,
      description:
        "With Bonatra's Health Coach as your dedicated partner, you receive unparalleled accountability and expert assistance throughout your journey.",
    },
    {
      id: 5,
      description:
        "Trust in the approval of our esteemed Doctor, who ensures the programs align with your needs and handholds you towards success.",
    },
    {
      id: 6,
      description:
        "Become part of a vibrant community of like-minded individuals, embracing better health and well-being with Bonatra.",
    },
   ];

   export const cgmFeatures = [
    {
      id: 1,
      description:
        "Choose Bonatra for proven results and thousands of success stories, validating our commitment to your well-being.",
    },
    {
      id: 2,
      description:
        "Bonatra's primary focus lies in driving adherence to your health goals, supported by our dedicated team of doctors who conduct scheduled follow-ups, ensuring your progress remains on track.",
    },
    {
      id: 3,
      description:
        "Bonatra offers effortless, simple 3-meal plans, featuring slight replacements in your day-to-day meals. For instance, relish keto parathas instead of traditional parathas and savor foxtail millet upmas. Discover a delightful and nutritious way to achieve your health goals with our tailored meal plans.",
    },
    {
      id: 4,
      description:
        "Our dedicated Health Coach takes equal partnership and accountability in guiding you through your health journey.",
    },
    {
      id: 5,
      description:
        "Rest assured, our Doctor approves the programs and provides expert support as you progress.",
    },
    {
      id: 6,
      description:
        "Join a thriving community of individuals embracing better health and well-being with Bonatra.",
    },
   ]

  export const featuresData = {
    data: bonatraFeatures,
    heading: "Why Choose Bonatra",
  }

  export const featuresDataDiabetesReversal = {
    data: diabetesReversalFeatures,
    heading: "Why Choose Bonatra",
  }

  export const featuresDataFitness = {
    data: fitnessFeatures,
    heading: "Why Choose Bonatra",
  }

  export const featuresDataCGM = {
    data: cgmFeatures,
    heading: "Why Choose Bonatra",
  }


   export default function handler(
    req: NextApiRequest,
    res: NextApiResponse<featuresData>
  ) {
    res.status(200).json({data: bonatraFeatures, heading:'Features'})
  }