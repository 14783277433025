import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const useGetListOfTestimonials = (payload?: { categories: any }) => {
  return useQuery(["getListOfTestimonials", payload], async () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.NEXT_PUBLIC_API_URL}/api/method/bonatra.bonatra_health_care.doctype.patient_testimonial.patient_testimonial.get_patient_testimonial`,
      headers: {
        'Cookie': 'full_name=Sanjay%20Kumar; sid=d97af7af5cbc1edc09f934c5316afa8ea336381e9c0ca7cd3beaf497; system_user=yes; user_id=sanjay%40bonatra.com; user_image='
      }
    };
    try {
      const response = await axios.request(config);
      return response.data.message;
    } catch (error) {
      console.error(error);
      return null;
    }


  });
};

export default useGetListOfTestimonials;
